import React from 'react'
import Jeff from '../../images/about-jeff.jpg'
import Jennifer from '../../images/about-jennifer.png'

const AboutBios = () => {
    return (
        <div className="about">
        <div className="container">
            <div className="about__rows">
                <div className="about__img">
                    <img src={Jeff} alt="" />
                </div>
                <div className="about__copy">
                    <p>
                        When Jeff is not making wine, scouting for grapes or trying new and different wines, he enjoys playing Frisbee and handball with his boys, hiking, camping and cooking just about anything that can possibly be cooked on a barbeque.  He enjoys smoking fish, meat and poultry as often as Jennifer can tolerate it.  And, having acquired the taste from his father, he enjoys single malt scotch almost as much as good wine.  He likes to read and recently started to take up crossword puzzles, but only the first few days of the week.  He enjoys traveling with his wife, usually to locations which are known either for their quality wine or beautiful beaches. 
        ​           </p>

                    <p>
                        Jeffrey Vallens was born and raised in Southern California. He earned a degree in English from the University of California Riverside before going on to law school and launching a successful career as a criminal defense attorney.
                    </p>
        
                    <p>
                        Jeff is licensed to practice before the California Supreme Court (all courts in California) as well as The United States Supreme Court, 9th Circuit Court of Appeals, U.S. District Court for the Southern District of California and The U.S. Court of Military Appeals. <a href="https://4criminaldefense.com/" rel="noopener noreferrer" target="_blank">https://4criminaldefense.com/</a>
                    </p>
                </div>
            </div>
            <div className="about__rows">
                <div className="about__img">
                    <img src={Jennifer} alt="" />
                </div>
                <div className="about__copy">
                    <p>
                        Jennifer is active in the chess community and runs scholastic chess tournaments under her company; OFF da ROOK Entertainment.  She teaches Yoga and Beginning Chess.  She is also a published author.  Her next book WILL THE REAL YOGI PLEASE STAND UP is due to be released in 2021.  To read her blog and find out more about her events and book release, please visit: <a href="https://www.jenvallens.com/" rel="noopener noreferrer" target="_blank">www.jenvallens.com</a>
                    </p>
                    <p>
                    Jeffrey and Jennifer reside in the Conejo Valley and have two sons, Evan and Mason and a dog named Henry.
                    </p>
                </div>
            </div>
        </div>   
    </div>
    )
}

export default AboutBios
