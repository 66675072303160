import React from 'react'
import Layout from '../components/Layout/Layout'
import Banner from '../components/Banner/Banner'
import AboutBios from '../components/AboutBios/AboutBios'



export const aboutPageData = graphql`
  query aboutPageQuery {
    sanityPage(slug: {current: {eq: "about-us"}}) {
      pageBanner {
        title
        image {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
	  }
  }
`

const AboutPage = ({data}) => {
    return (
        <Layout>
            <Banner 
                heading={ data.sanityPage.pageBanner.title }
                image={ data.sanityPage.pageBanner.image.asset.fluid }
                bannerAlt="banner--sm"
            />
            <AboutBios />

        </Layout>
    )
}

export default AboutPage;
